import React, { ReactElement } from "react";

export default function Heart(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="none"
            viewBox="0 0 80 80"
        >
            <mask
                id="mask0_5_116"
                style={{ maskType: "alpha" }}
                width="80"
                height="80"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H80V80H0z"></path>
            </mask>
            <g fill="#FF8A00" mask="url(#mask0_5_116)">
                <path d="M63.447 41.26c-1.428-7.294-6.767-12.032-13.61-12.032a18.122 18.122 0 00-9.4 2.934 18.047 18.047 0 00-9.476-3.009c-6.768 0-12.258 4.888-13.611 12.108-1.955 9.926 3.986 23.086 21.432 30.08a4.36 4.36 0 003.308 0c17.372-7.144 23.237-20.23 21.357-30.156v.075zm-23.01 24.365c-13.762-5.79-18.575-15.717-17.146-23.161.827-4.512 3.835-7.295 7.67-7.295 2.331 0 5.039 1.053 7.67 3.008a3.01 3.01 0 003.61 0c2.632-1.955 5.264-2.932 7.595-2.932 3.835 0 6.843 2.782 7.67 7.144 1.505 7.444-3.308 17.37-17.07 23.236zM43.444 19.753v-7.745a3.008 3.008 0 00-6.015 0v7.745a3.008 3.008 0 106.015 0zM64.049 13.361a3.007 3.007 0 00-3.986 1.58l-3.309 7.745a3.032 3.032 0 005.566 2.407l3.308-7.746a3.01 3.01 0 00-1.58-3.986h.001zM18.553 25.093a3.017 3.017 0 005.565-2.332l-3.384-7.82a3.017 3.017 0 00-5.565 2.33l3.384 7.822z"></path>
            </g>
        </svg>
    );
}
