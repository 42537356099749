import React, { ReactElement } from "react";

export default function Clock(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="none"
            viewBox="0 0 80 80"
        >
            <mask
                id="mask0_1_53"
                style={{ maskType: "alpha" }}
                width="80"
                height="80"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H80V80H0z"></path>
            </mask>
            <g mask="url(#mask0_1_53)">
                <path
                    fill="#FF8A00"
                    d="M40 70c-16.543 0-30-13.457-30-30s13.457-30 30-30a2.5 2.5 0 010 5c-13.785 0-25 11.214-25 25 0 13.784 11.215 24.999 25 24.999s25-11.215 25-25a2.5 2.5 0 115 0c0 16.543-13.458 30-30 30V70zm0-55c-.65 0-1.3-.275-1.775-.725a2.63 2.63 0 01-.725-1.774c0-.65.275-1.3.725-1.775.95-.925 2.625-.925 3.55 0A2.63 2.63 0 0142.5 12.5c0 .65-.275 1.3-.725 1.775A2.63 2.63 0 0140 15zm23.1 15.425c-.525-1.275.072-2.75 1.35-3.275 1.275-.525 2.75.075 3.275 1.35.525 1.275-.075 2.75-1.35 3.275-.325.125-.65.2-.95.2-1 0-1.927-.575-2.325-1.55zm-5.427-8.1a2.52 2.52 0 010-3.55 2.49 2.49 0 013.524 0 2.515 2.515 0 010 3.55 2.492 2.492 0 01-1.747.724c-.65 0-1.275-.25-1.777-.725zM49.575 16.9c-1.277-.525-1.875-2-1.35-3.275.525-1.275 1.975-1.876 3.275-1.35 1.275.524 1.875 2 1.325 3.274-.375.95-1.325 1.525-2.3 1.525-.325 0-.65-.05-.95-.175zM67.5 42.5c-.65 0-1.302-.276-1.775-.726A2.617 2.617 0 0165 40c0-.65.273-1.3.725-1.775.95-.925 2.625-.925 3.55 0A2.63 2.63 0 0170 40c0 .65-.275 1.3-.725 1.775a2.63 2.63 0 01-1.775.725zm-17.503 5c-.375 0-.757-.086-1.115-.263l-10-5a2.502 2.502 0 01-1.382-2.238V22.5a2.5 2.5 0 015 0v15.955l8.617 4.31a2.5 2.5 0 01-1.12 4.735z"
                ></path>
            </g>
        </svg>
    );
}
