import React, { ReactElement } from "react";

export default function Circles(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="none"
            viewBox="0 0 80 80"
        >
            <mask
                id="mask0_5_117"
                style={{ maskType: "alpha" }}
                width="80"
                height="80"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H80V80H0z"></path>
            </mask>
            <g fill="#FF8A00" mask="url(#mask0_5_117)">
                <path d="M62.4 25.708a7.352 7.352 0 01-7.354-7.354 7.354 7.354 0 117.354 7.354zm0-11.03a3.766 3.766 0 10-.001 7.53 3.766 3.766 0 000-7.53zM11.639 40.147a7.355 7.355 0 119.148 7.085v7.534a7.355 7.355 0 11-3.587 0v-7.534a7.35 7.35 0 01-5.561-7.085zm11.03 0h.001a3.769 3.769 0 00-3.766-3.766 3.768 3.768 0 103.766 3.766zm-7.443 21.704a3.768 3.768 0 107.536 0 3.768 3.768 0 00-7.536 0zM62.4 69.205a7.354 7.354 0 110-14.709 7.354 7.354 0 010 14.709zm0-11.031a3.766 3.766 0 100 7.531 3.766 3.766 0 000-7.532v.001zM18.993 11.09a7.354 7.354 0 017.174 5.919h7.713a7.357 7.357 0 0111.093-4.81 7.355 7.355 0 11-10.914 8.398h-8.07a7.352 7.352 0 01-8.492 5.055 7.356 7.356 0 01-3.455-12.68 7.355 7.355 0 014.95-1.882zM41.055 22.12a3.768 3.768 0 100-7.535 3.768 3.768 0 000 7.535zm-22.062 0a3.766 3.766 0 100-7.532 3.766 3.766 0 000 7.532z"></path>
                <path d="M62.4 47.501l-1.884-.269-13.004 13.094a7.353 7.353 0 11-1.704-3.318l11.57-11.66a7.355 7.355 0 01-2.063-3.407h-7.893a7.356 7.356 0 01-11.319 4.252 7.354 7.354 0 1111.32-7.84h7.892a7.35 7.35 0 018.35-5.458A7.355 7.355 0 0162.398 47.5zM40.337 58.173a3.768 3.768 0 000 7.534 3.767 3.767 0 000-7.534zm0-21.703a3.767 3.767 0 103.767 3.677 3.769 3.769 0 00-3.766-3.767v.09zm22.063 0a3.769 3.769 0 00-3.766 3.797 3.766 3.766 0 107.532-.12 3.768 3.768 0 00-3.767-3.767v.09z"></path>
            </g>
        </svg>
    );
}
