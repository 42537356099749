import React, { ReactElement } from "react";
import Clock from "../icons/clock";
import Circles from "../icons/circles";
import Heart from "../icons/heart";

import FeatureList from "@components/shared/featureList";

const whyUsList = [
    {
        title: "Save Time & Money",
        description:
            " Outsourcing your cleaning services can help streamline your expenses and reduce cleaning time by about 25% while ensuring a spotless, disinfected environment.",
        icon: <Clock />,
    },
    {
        title: "Professional Experts, Advanced Technology",
        description:
            "Our cleaning experts are thoroughly trained and use proprietary cleaning and disinfecting systems that dramatically decrease dust and bacteria, among other compounds.",
        icon: <Circles />,
    },
    {
        title: "Healthy, Worry-Free Environments",
        description:
            "Maintaining a clean and healthy environment is vital for a bacteria and virus-free space, whether residential or commercial, to protect you and your visitors or clients from any airborne diseases or infectious diseases.",
        icon: <Heart />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <FeatureList
            featureList={whyUsList}
            mainTitle={
                <>
                    Custom Cleaning Plans For <br /> Clean, Safe &amp; Healthy
                    Environments
                </>
            }
            classes={{
                mainTitleClasses:
                    "text-center text-xl lg:text-4xl mx-auto mb-14 ",
                innerSectionClasses: "lg:flex flex-wrap justify-center ",
                oneBoxClasses: "text-center mb-12 lg:mb-20 w-full lg:w-1/3",
                iconClasses: "children:mx-auto",
                titleClasses: "font-medium text-lg mb-5 lg:text-xl mt-6",
                descriptionClasses:
                    "font-light text-sm lg:text-base text-center w-4/5 mx-auto mt-2",
            }}
            colors={{
                descriptionColor: "#777777",
            }}
        />
    );
}
